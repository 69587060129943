@media (max-width: 1200px) {
  .footer-col.col-md-9 {
    width: 60%;
  }
}
.mainContainer {
  padding: 64px 0px 64px 0px !important;
}
.text-group {
  padding: 1rem 0 0 0;
}

.footer-custom-group {
  padding: 1rem 0px 0px;
  display: flex;
  justify-content: space-between;
  width: 300px;
}
.company,
.product {
  margin-top: -30px !important;
}
@media screen and (max-width: 1000px) {
  .company,
  .product {
    margin-top: 0 !important;
  }
}

.rowCustom,
.column {
  flex: 0 0 auto !important;
  width: auto !important;
  flex-wrap: nowrap !important;
}
@media screen and (max-width: 768px) {
  .rowCustom,
  .column {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: center;
  }

  .com-text {
    letter-spacing: -0.1px;
  }
  .com-text-group {
    padding: 1rem 0px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .text-group {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .footer-col {
    position: relative;
  }
  .copyright-main-container {
    order: 5;
  }
  .copyRight-span {
    display: flex;
    flex-direction: column;
    display: flex;
    flex-direction: column-reverse;
  }
  .mainContainer {
    padding: 55px 0px 35px 0px !important;
  }
  .footerLogo {
    padding-bottom: 35px;
    display: flex;
    justify-content: center;
  }
  .product {
    padding-top: 28px;
  }
}

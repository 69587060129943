.galeHeadingCol {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5rem 0 0 0;
  align-items: center;
  justify-content: center;
}

.galeContainer {
  max-width: 1320px;
}
.galeHeading {
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 55px;
  line-height: 28px;
  /* identical to box height, or 51% */
  padding-top: 50px;
  padding-bottom: 30px;
  text-align: center;
  letter-spacing: -0.1px;
  display: flex;
  justify-content: center;
}
.galeSubHeading {
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 26px;
  line-height: 32px;
  /* or 123% */

  text-align: center;
  letter-spacing: -0.1px;
  padding-top: 20px;
  padding-bottom: 30px;
  color: #111111;
}

.galeBlueHeading {
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 26px;
  line-height: 32px;
  /* or 123% */

  text-align: center;
  letter-spacing: -0.1px;
  padding-bottom: 30px;

  color: #26bbff;
}

.galeParagraph {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 28px;
  /* or 140% */

  text-align: center;
  letter-spacing: -0.1px;

  color: #444444;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.galeParagraph p {
  max-width: 800px;
}

.galeBlueDiv {
  width: 799px;
  height: 225px;
  left: 433px;
  top: 685px;

  background: #eaf9ff;
  border-radius: 33px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.galeGradiant {
  width: 76px;
  height: 17px;
  left: 793px;
  top: 729px;

  background: linear-gradient(90deg, #54ebec 0%, #23b3f5 92.86%);
  border-radius: 30px;
}

.galeGradiantParagraph {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 26px;
  line-height: 32px;
  /* or 123% */

  text-align: center;
  letter-spacing: -0.1px;

  color: #111111;
  max-width: 600px;
  padding-top: 14px;
}

.galeGradiantSecPara {
  max-width: 629px;
  text-align: center;
}

.threeColumnsRow {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding-top: 45px;
}

.threeColumns {
  max-width: 245px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.threeColumnsHeading {
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 26px;
  line-height: 32px;
  /* or 123% */

  text-align: center;
  letter-spacing: -0.1px;

  color: #111111;
}
.threeColumnsImages {
  height: 90px;
}
.checkListSpan {
  display: flex;
  align-items: center;
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 33px;
}

.checkListDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: Lato;
  font-style: normal;
  font-weight: 800;
  font-size: 20px;
  line-height: 28px;
  /* or 140% */

  text-align: center;
  letter-spacing: -0.1px;

  color: #444444;
}
.rightVector {
  padding-right: 10px;
  padding-bottom: 10px;
}

.checkListHeading {
  font-family: Lato;
  font-style: normal;
  font-weight: 800;
  font-size: 20px;
  line-height: 28px;
  /* or 140% */

  text-align: center;
  letter-spacing: -0.1px;

  color: #444444;
}

.galeSolutions {
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 29px;
  /* or 145% */

  text-align: center;
  letter-spacing: -0.1px;

  color: #111111;
  max-width: 200px;
  min-width: 245px;
}
.galeSolutionsDiv {
  padding-right: 20px;
}

.galeSolutionsImage {
  min-height: 95px;
}
.galeSubHeadingShort {
  font-family: Lato;
  font-style: normal;
  font-weight: 500;
  font-size: 37px;
  line-height: 38px;
  /* or 103% */

  text-align: center;
  letter-spacing: -0.1px;
  max-width: 900px;
}
.galeCustomBtn {
  padding: 9px 18px 10px 25px !important;
}
.learnMore {
  font-size: 16px;
}

.galeTextContainer {
  max-width: 500px;
}
.textLeft {
  text-align: left;
  padding-top: 0px;
}

.galeRowContainer {
  display: flex;
  justify-content: center;
  padding: 160px 0 0 0;
}
.galeFocusPara {
  max-width: 615px;
  text-align: center;
}

.theSolution {
  font-family: Lato;
  font-style: normal;
  font-weight: 500;
  font-size: 37px;
  line-height: 56px;
  /* identical to box height, or 151% */

  letter-spacing: -0.1px;

  color: #111111;
}

.marginRight {
  margin-right: 50px;
}

.marginLeft {
  margin-left: 50px;
}

@media screen and (max-width: 1440px) {
  .ContainerMain {
    max-width: 100% !important;
  }
}

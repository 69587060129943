.variable-width {
    width: 70%;
    text-align: center;
    margin: 0 auto;
}
.slick-dots {
    position: initial;
    padding-bottom: 15px;
}
.sliderText{
    padding-top:10px;
    padding-bottom:10px;
}
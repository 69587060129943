.up-blog-img-single {
  width: 880px;
  max-width: 880px;
}

.gradiantHeadingText {
  display: flex;
  height: 100%;
  align-items: flex-end;
  padding-bottom: 30px;
  font-family: Lato;
  font-weight: 500;
  font-size: 34px;
  line-height: 41px;
  letter-spacing: -0.1px;
  color: #ffffff;
  padding-left: 40px;
}

.singleBlog {
  padding-bottom: 20px;
  padding-top: 20px;
}

.blogImage {
  max-height: 219px;
  border-radius: 22px;
}

.fullBodyImg {
  border-radius: 22px;
}

@media screen and (max-width: 1320px) {
  .up-blog-img-single {
    width: 100%;
    max-width: 880px;
  }
}

@media screen and (max-width: 768px) {
  .up-blog-img-single {
    width: 880px;
  }
}

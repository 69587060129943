@media screen and (max-width: 1000px) {
  .container-main {
    max-width: 100% !important;
    padding: 0 30px !important;
  }
  /* .navbar-collapse.show .buttonForm {
    position: relative;
  }
  .expanded-bg-header .nav-container-main {
    display: none;
  } */
  .nav-navbar{
    padding: 0!important;
  }
  .navbar-collapse.show {
    min-height: 100vh;
  }
  .nav-navbar-text {
    width: 100%;
  }
  .navbar-collapse {
    transition: none !important;
  }
  .buttonForm {
    display: none;
  }
}
.navbar-collapse {
  flex-grow: 0 !important;
}
.expanded-bg-header .nav-container-main {
  height: 100%;
}
.nav-container-main {
  position: relative;
  padding-top: 15px;
}
.nav-container-main .close-icon {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.nav-container-main .close-icon img {
  width: 41px;
  height: 46px;
}

.nav-navbar-text {
  padding-top: 5px;
  padding: 6px;
  padding-left: 0px;
}

.btn-text-name {
  font-family: Plus Jakarta Sans;
}

.groupRow {
  justify-content: center;
  align-items: CENTER;
  display: FLEX;
  flex-direction: ROW;
}

@media screen and (max-width: 769px) {
  .improve,
  .decrease,
  .moreTime {
    max-width: 100% !important;
  }
  .group-col {
    padding-top: 50px;
  }
  .navbar-toggler-icon {
    width: 24px;
    height: 24px;
  }
}

@media screen and (max-width: 992px) {
  .buttonForm {
    padding-top: 7px;
  }
}

@media screen and (max-width: 600px) {
  .nav-container-main {
    padding-left: 20px !important;
    padding-top: 0;
  }
  .group-col {
    padding: 40px 5px 40px 5px !important;
  }
  .groupRow:first-child {
    padding-top: 60px;
  }
}

@media screen and (max-width: 320px) {
  .buttonForm {
    padding-top: 18px;
  }
  .btn.btn-login {
    padding: 7px;
  }
  .btn-text-name {
    font-size: 15px;
  }
}
.expanded-bg-header {
  background: #1d4354;
  height: 100vh;
  padding: 2rem;
}

@import url("https://fonts.googleapis.com/css2?family=Lato:wght@100,200,300,400,500,600,700&display=swap");

.blog-head-main-row {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.blog-head-main {
  margin-top: 139px;
  max-width: 1025px;
}
.blog-main {
  padding-right: 70px !important;
  /* padding-left: 0px !important; */
  margin-left: -10px;
}
.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
}

.active-page a {
  background: #26bbff;
  cursor: pointer;
  border-radius: 7px;
  color: white;
  width: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.page-item a {
  width: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.page-item a {
  border-radius: 7px;
}
.page-link {
  border-radius: 7px !important;
  cursor: pointer;
  color: rgb(151, 151, 151);
  border: none;
}
.page-link:hover {
  background: #26bbff;
}

.blog-group-text {
  font-family: Lato;
  font-style: normal;
  font-weight: 500;
  font-size: 21px;
  line-height: 24px;
  letter-spacing: -0.1px;
  color: #111111;
  padding-top: 19px;
}
.blog-group-second-text {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.1px;
  color: #636363;
  padding-top: 7px;
}
.blog-featured {
  font-family: Lato;
  font-style: normal;
  font-weight: 500;
  font-size: 21px;
  line-height: 24px;
  letter-spacing: -0.1px;
  color: #111111;
}
.blog-featured-second {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.1px;
  color: #636363;
  padding-top: 11px;
  border-bottom: 1px solid #d8d8d8;
  padding-bottom: 27px;
}
.blog-featured-second-two {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.1px;
  color: #636363;
  padding-top: 36px;
  border-bottom: 1px solid #d8d8d8;
  padding-bottom: 31px;
}

.blog-text-title {
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 56px;
  /* identical to box height, or 117% */

  text-align: center;
  letter-spacing: -0.1px;

  color: #111111;
}
.blog-text-second-title {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  /* or 125% */

  text-align: center;
  letter-spacing: -0.1px;

  color: #636363;
  padding-top: 18px;
  padding-bottom: 35px;
}
.part-contain {
  width: calc(100% - 15px);
  height: 426px;
  background: #eaf9ff;
  border-radius: 5px;
  margin: 113px 0 0 15px;
  background-image: url(../../image/blogplayimg.png);
  background-repeat: no-repeat;
  background-position: 10px;
}
.forever-text {
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 30px;
  /* or 107% */

  letter-spacing: -0.1px;

  color: #111111;
  padding-top: 48px;
}
.btn-btn-get-blog.btn-outline-success,
.btn-btn-get-blog,
.btn-btn-get-blog:hover,
.btn-btn-get-blog.btn-outline-success:hover {
  background: #26bbff;
  width: 207px;
  height: 47px;
  border: none;
  /* or 150% */

  color: #ffffff;
}
.get-text-blog {
  border-radius: 4px;
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 16 px;
  line-height: 24px;
}
.blog-play-img {
  padding-top: 64px;
}
/* .blog-row {
  margin-left: -65px;
} */
.blog-container {
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-items: center !important;
  max-width: 100% !important;
}
.blogMain {
  display: flex;
  /* flex-direction: column; */
  max-width: 1320px;
}

.up-blog-img {
  cursor: pointer;
}

.sidebar-container {
  max-width: 390px;
  min-width: 390px;
}
@media screen and (max-width: 1200px) {
  .blog-container {
    max-width: 920px;
  }
  .blog-row {
    flex-direction: column;
    gap: 20px;
  }
  .part-contain {
    width: 100%;
  }
  .sidebar-container,
  .sidebar {
    max-width: 300px;
  }
}

@media screen and (max-width: 768px) {
  .up-blog-img {
    max-width: 100%;
  }
  .blogDataCol {
    min-width: 900px;
  }
  .sidebar,
  .sidebar-container,
  .part-contain {
    display: none !important;
  }
  .blog-container {
    max-width: 100% !important;
    padding: 0 30px;
    padding-bottom: 50px;
  }
  .blog-main {
    margin: 0;
    padding: 0 !important;
  }

  .sidebar {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .part-contain {
    width: 100%;
    margin: 113px 0 80px 0px;
    padding: 25px;
  }
  .blog-play-img {
    width: 25%;
    padding-top: 70px;
  }
}
@media screen and (max-width: 600px) {
  .blog-head-main {
    margin-top: 100px;
  }
  .blog-main {
    margin-bottom: 0 !important;
    padding: 30px 10px !important;
  }
  .blog-row {
    flex-direction: column;
    gap: 20px;
  }
  .blog-text-second-title {
    padding-bottom: 20px;
    padding-left: 20px;
    padding-right: 20px;
  }

  .blog-text-title {
    font-size: 34px;
  }
}

.imageGroup {
  position: relative;
}
.blogGradiant {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #0077c8 100%);
  border-radius: 12px;
  position: absolute;
  width: 100%;
  height: 100%;
}

.blog-row {
  max-width: 1860px;
}

html,
body {
  height: 100%;
  padding: 0 !important;
}

.navbar-toggler, .collapsed{
  margin-right: 0!important;
}

body * {
  box-sizing: border-box;
}

#root {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.menuButton {
  margin-right: inherit;
}

.title {
  text-align: center;
  flex-grow: 1;
}

.title a {
  color: #fff;
  white-space: nowrap;
  text-decoration: none;
}

.accountButton {
  float: right;
}

.username {
  float: left;
  padding: 10px;
  font-style: italic;
}

.app-spinner {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.App {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.GridPage {
  overflow: hidden !important;
}
.GridPage .heightWrapper {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.RightArrow {
  cursor: pointer;
  padding-left: 15px;
}


.paginationReact {
  display: flex;
  justify-content: center;
  margin: 0;
  padding: 0;
  margin: 20px 0 0 0;
  gap: 10px;
  margin-bottom: 20px;
}

.paginationReact__link {
  cursor: pointer;
  color: #29abef !important;
  padding: 10px !important;
  border-radius: 4px !important;
}
.paginationReact li::marker {
  content: "";
}
.paginationReact li a[role="button"] {
  font-size: 14px;
  font-weight: bold;
  color: #29abef;
  padding: 16px 18px;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none;
}

.paginationReact__link--active a[role="button"] {
  background-color: #fff !important;
  color: #29abef !important;
  border: 1px solid black;
}
body {
  font-family: Plus Jakarta Sans;
}
.about-navbar-text {
  font-family: Plus Jakarta Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.1px;
  color: #111111;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 16px;
}
.about-btn-login,
.about-btn-login:hover {
  background: #26bbff;
  border-radius: 4px;
  border: none;
  align-items: flex-start;
  padding: 10px 16px;

  /* width: 78px;
  height: 40px; */
}
.about-btn-text-name {
  font-family: Plus Jakarta Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.1px;
  color: #ffffff;
  flex: none;
  order: 0;
  flex-grow: 0;
}
.about-text.aboutChange {
  font-family: Plus Jakarta Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 64px;
  text-align: center;
  letter-spacing: -0.1px;
  color: #111111;
  flex: none;
  order: 0;
  flex-grow: 0;
  padding-top: 64px;
}
.about-desc.aboutChangeDesc {
  font-family: Plus Jakarta Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 32px;
  text-align: center;
  letter-spacing: -0.1px;
  color: #444444;
  flex: none;
  order: 1;
  flex-grow: 0;
  padding-bottom: 30px;
}
.about-group-text {
  font-family: Plus Jakarta Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -0.1px;
  color: #111111;
  padding: 2rem 0 0 0;
}
.about-group-second-text {
  font-family: Plus Jakarta Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 24px;
  letter-spacing: -0.1px;
  color: #444444;
  padding: 12px 0 0 0;
}
.our-about-text1 {
  font-family: "Lato";
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 56px;
  text-align: center;
  letter-spacing: -0.1px;
  color: #111111;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 15px 0px;
  padding-top: 95px;
}
.our-about-desc.ourChange {
  font-family: Plus Jakarta Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  letter-spacing: -0.1px;
  color: #444444;
  padding-bottom: 64px;
}
.bio-name-text-about {
  font-family: Plus Jakarta Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -0.1px;
  color: #111111;
  display: flex;
  flex-direction: column;
  /* padding: 1rem 0 0 0; */
}
.desc-about-text {
  font-family: Plus Jakarta Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 24px;
  letter-spacing: -0.1px;
  color: #444444;
}
.board-text {
  font-family: Plus Jakarta Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 56px;
  letter-spacing: -0.1px;
  color: #111111;
  text-align: center;
  padding: 80px 0 0 0;
}
.board-bio-name-text {
  font-family: Plus Jakarta Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: -0.1px;
  color: #111111;
  padding: 2rem 0 0 0;
}
.board-desc-about-text {
  font-family: Plus Jakarta Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 24px;
  letter-spacing: -0.1px;
  color: #444444;
  padding-top: 0.5rem;
  /* opacity: 0.5; */
}
.bio-btn-text {
  font-family: Plus Jakarta Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.1px;
  color: #26bbff;
  padding: 24px 0 0 0;
  cursor: pointer;
}
.modal-name-text {
  font-family: Plus Jakarta Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -0.1px;
  color: #111111;
}
.modal-desc-text {
  font-family: Plus Jakarta Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 24px;
  letter-spacing: -0.1px;
  color: #444444;
}
.bio-desc {
  font-family: Plus Jakarta Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 24px;
  letter-spacing: -0.1px;
  color: #111111;
  padding-right: 30px;
  padding-left: 11px;
}
.modal-header {
  align-items: flex-start;
  border-bottom: none !important;
}
.modal-header .btn-close {
  color: #979797;
  transform: matrix(-1, 0, 0, 1, 0, 0);
}
.com-text {
  font-family: Plus Jakarta Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: 0.9px;
  color: #111111;
}
.about-group-col {
  padding-top: 2rem;
  text-align: center;
}
.bio-name-text-about {
  padding-top: 25px;
}
.personGroup {
  justify-content: flex-start;
}
.Img-desc {
  min-height: 160px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.Img-css {
  max-width: 264px;
  height: 352px;
}
.Img-css img {
  width: 100%;
  height: 100%;
  border-radius: 25px;
}

.modalImage img{
  border-radius: 12px;
}
@media only screen and (max-width: 992px) {
  .Img-desc {
    min-height: 215px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

@media only screen and (max-width: 575px) {
  .Img-main {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: center;
    padding: 0 0 0 1rem;
    width: 100%;
  }

  .bio-name-text-about {
    display: flex;
    flex-direction: column;
    padding-top: 0;
  }
  .Img-css {
    min-width: 143px;
    max-width: 143px;
    height: 189.33px;
    margin-right: 0.3rem;
  }
  .Img-desc {
    padding-left: 1rem;
    text-align: left;
    justify-content: end;
    min-height: auto;
  }
}




@media only screen and (max-width: 767px) {

  .Img-desc{
    min-height: 190px;
  }
  .personGroup {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .bio-name-text-about {
    font-size: 20px;
    line-height: 26px;
    font-family: Lato;
  }

  .about-text.aboutChange {
    font-family: lato;
    font-weight: 700;
    font-size: 36px;
    font-style: normal;
    align-items: center;
    line-height: 42px;
    color: #111111;
    margin: 40px 0px 0px 0px;
    padding: 0;
  }
  .about-desc.aboutChangeDesc {
    font-family: "Lato";
    font-weight: normal;
    line-height: 28px;
    font-size: 20px;
    font-style: normal;
    padding: 0px 19px 0px 20px;
    margin: 0px;
  }
  .about {
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    align-items: center;
  }
  .about-desc {
    display: block;
  }

  .board-text {
    padding-top: 20px;
    font-size: 30px;
    margin-bottom: -45px;
  }
  .about-desc:first-child {
    margin-top: 14px;
    margin-bottom: 30px;
  }
  .our-about-text1 {
    font-family: "Lato";
    font-weight: 700;
    font-size: 30px;
    font-style: normal;
    align-items: center;
    line-height: 56px;
    color: #111111;
    padding-bottom: 0px;
    margin-bottom: 0px;
    padding-top: 15px;
  }
  .our-about-desc.ourChange {
    font-family: "Lato";
    font-weight: normal;
    line-height: 28px;
    font-size: 20px;
    font-style: normal;
    padding-left: 7%;
    padding-right: 7%;
    padding-bottom: 0px;
  }

  .desc-about-text {
    font-family: Plus Jakarta sans-serif;
    font-weight: 400;
    font-size: 17px;
    font-style: normal;
    margin-top: 15px;
    letter-spacing: -0.1px;
  }
  .bio-btn-text {
    font-weight: 400;
  }
  .bio-btn-text span {
    border-bottom: 1px solid #26bbff;
  }
  .col-board {
    text-align: center;
  }

  .about-group-text {
    font-family: lato;
    line-height: 28.8px;
  }
  .about-group-second-text {
    font-family: lato;
    line-height: 24px;
    width: 75%;
  }
  .about-group-col {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  .board-bio-name-text {
    font-family: Lato;
    font-size: 22px;
    line-height: 28px;
    font-weight: 600;
    padding-top: 1.5rem;
  }
  .board-desc-about-text {
    font-size: 16px;
    line-height: 24px;
    font-family: Lato;
    width: 80%;
    margin: auto;
  }
  .col-board:first-child .board-desc-about-text {
    width: 70%;
  }
}

@media screen and (max-width: 550px) {
  .mainGroup {
    padding: 5rem 0px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

@media only screen and (max-width: 320px) {
  .Img-css {
    min-width: 110px;
    max-width: 110px;
  }
}
.linkedinIcon {
  margin-left: 10px;
  font-size: 20px;
}

.linkedinIcon a {
  color: inherit;
}

@import url(https://fonts.googleapis.com/css2?family=Lato:wght@100,200,300,400,500,600,700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-width: 980px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html,
body {
  height: 100%;
  padding: 0 !important;
}

.navbar-toggler, .collapsed{
  margin-right: 0!important;
}

body * {
  box-sizing: border-box;
}

#root {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.menuButton {
  margin-right: inherit;
}

.title {
  text-align: center;
  flex-grow: 1;
}

.title a {
  color: #fff;
  white-space: nowrap;
  text-decoration: none;
}

.accountButton {
  float: right;
}

.username {
  float: left;
  padding: 10px;
  font-style: italic;
}

.app-spinner {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.App {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.GridPage {
  overflow: hidden !important;
}
.GridPage .heightWrapper {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.RightArrow {
  cursor: pointer;
  padding-left: 15px;
}


.paginationReact {
  display: flex;
  justify-content: center;
  margin: 0;
  padding: 0;
  margin: 20px 0 0 0;
  grid-gap: 10px;
  gap: 10px;
  margin-bottom: 20px;
}

.paginationReact__link {
  cursor: pointer;
  color: #29abef !important;
  padding: 10px !important;
  border-radius: 4px !important;
}
.paginationReact li::marker {
  content: "";
}
.paginationReact li a[role="button"] {
  font-size: 14px;
  font-weight: bold;
  color: #29abef;
  padding: 16px 18px;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none;
}

.paginationReact__link--active a[role="button"] {
  background-color: #fff !important;
  color: #29abef !important;
  border: 1px solid black;
}
@media (min-width: 1400px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1220px;
  }
}

body {
  font-family: "Lato";
}
.logo-image {
  width: 116px;
  height: 20px;
}
.bg-header {
  background: linear-gradient(135deg, #3c8ce7 0%, #00eaff 100%);
}
.header-btn {
  padding-top: 15px;
}
img {
  max-width: 100%;
}
.banner {
  padding: 100px;
  padding-left: 5px;
}
.main-text {
  font-family: "Lato";
  font-style: normal;
  font-weight: bold;
  font-size: 58px;
  line-height: 64px;
  letter-spacing: -0.1px;
  color: #ffffff;
  padding: 2rem 0 0 0;
}
.btn.btn-login,
.btn.btn-login:hover {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 10px 16px !important;
  background: #ffffff;
  border-radius: 4px;
  color: #26bbff;
  border-color: white;
  padding: 10px 20px 10px;
}
.about-navbar .btn.btn-login {
  background: #26bbff;
  border-color: #26bbff;
  min-width: 90px;
  justify-content: center;
}
.about-navbar .btn.btn-login span {
  color: #fff !important;
}
.about-navbar.bg-header .btn.btn-login {
  background: #fff;
  border-color: #fff;
}
.about-navbar.bg-header .btn.btn-login span {
  color: #26bbff !important;
}
.about-navbar .white-logo {
  display: block;
}
.about-navbar .color-logo {
  display: none;
}
.about-navbar.bg-header .white-logo {
  display: none;
}
.about-navbar.bg-header .color-logo {
  display: block;
}
.expanded-bg-header .white-logo {
  display: block;
}
.expanded-bg-header .color-logo {
  display: none;
}

.nav-navbar-text.active-nav {
  border-bottom: 3px solid #111111;
  padding-bottom: 5px;
}
.about-navbar.bg-header .nav-navbar-text.active-nav {
  border-color: #fff;
}
.expanded-bg-header .navbar-nav {
  padding-top: 1rem;
}
.expanded-bg-header .nav-navbar-text {
  padding: 1rem 0;
  margin: 0;
}
.nav-navbar-text {
  font-family: "Lato";
  font-style: normal;
  font-weight: 500 !important;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.1px;
  color: #ffffff !important;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 16px;
  text-decoration: none;
}
.btn-text-name {
  font-family: "Lato";
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.1px;
  color: #26bbff;
  flex: none;
  order: 0;
  flex-grow: 0;
}
.banner {
  background: url(/static/media/home.6e552998.png),
    linear-gradient(135deg, #3c8ce7 0%, #00eaff 100%);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
}
.mian-second-text {
  font-family: "Lato";
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -0.1px;
  color: #ffffff;
  line-height: 2;
  padding: 1rem 0 0 0;
}

.group-text {
  font-family: "Lato";
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -0.1px;
  color: #111111;
  padding: 1rem 0 0 0;
  padding-right: 3rem;
}
.group-second-text {
  font-family: "Lato";
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 24px;
  letter-spacing: -0.1px;
  color: #444444;
  padding: 12px 0 0 0;
}
.group-col {
  padding-top: 97px;
  padding-left: 25px;
  padding-right: 25px;
}
.group-col:last-child {
  padding-right: 0px;
  padding-left: 40px;
}

.authorization-row1 {
  padding: 160px 0 0 0;
}
.authorization-row2 {
  padding: 70px 0 0 0;
}
.contain-image {
  border-radius: 12px;
}
.contain-text {
  font-family: "Lato";
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 56px;
  letter-spacing: -0.1px;
  color: #111111;
  padding-top: 18px;
}
.contain-text-second {
  font-family: "Lato";
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: -0.1px;
  color: #444444;
  padding: 25px 0 0 0;
}
.thought-text {
  font-family: "Lato";
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 56px;
  text-align: center;
  letter-spacing: 0.6px;
  color: #fff;
  margin-bottom: 0.7rem;
}
.practice-text {
  font-family: "Lato";
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  letter-spacing: 0.6px;
  color: #fff;
  padding: 1% 0 0 0;
  min-height: 70px;
}
.General-text {
  font-family: "Lato";
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: -0.1px;
  color: #fff;
  text-align: center;
  padding: 1% 0 51px 0;
  margin-top: 40px;
}
.get-text {
  font-family: Plus Jakarta Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  color: #ffffff;
}
.btn-btn-get.btn-outline-success,
.btn-btn-get,
.btn-btn-get:hover,
.btn-btn-get.btn-outline-success:hover {
  background: #26bbff;
  border-radius: 4px;
  border: none;
  font-family: "Lato";
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.3px;
  color: #ffffff;
  padding: 1rem 2rem 1rem 2rem !important;
}
.btn-btn-get-home-bottom {
  padding: 20px 54px 20px 30px !important;
  background: #26bbff !important;
  color: white !important;
  border: none !important;
}
.btn-check:focus + .btn-outline-success,
.btn-outline-success:focus {
  box-shadow: none;
}
.forever-text-home {
  font-family: Plus Jakarta Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 56px;
  letter-spacing: -0.1px;
}
.rights-text {
  font-family: "Lato";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #787878;
  padding: 1rem 0 0 0;
}
.com-text {
  font-family: "Lato";
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -0.1px;
  color: #111111;
}
.ab-text {
  font-family: "Lato";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 32px;
  display: flex;
  align-items: center;
  letter-spacing: -0.1px;
  color: #444444;
}
.about-navbar .nav-navbar-text {
  font-family: "Lato";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.1px;
  color: #111111 !important;
  flex: none;
  order: 0;
  flex-grow: 0;
  text-decoration: none;
}

.about-navbar.bg-header .nav-navbar-text {
  color: #fff !important;
}

.modal-content {
  border-radius: 12px !important;
}
.modal-header .btn-close {
  margin: 0px 0px 7rem 0 !important;
}
.btn-btn-get-home.btn-outline-success,
.btn-btn-get-home,
.btn-btn-get-home:hover,
.btn-btn-get-home.btn-outline-success:hover {
  background: #1d4354;
  border-radius: 4px;
  border: none;
  font-family: "Lato";
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.3px;
  color: #ffffff;
  padding: 1rem 2rem 1rem 2rem !important;
}
.new-dot-home-col {
  margin-top: -30px !important;
}
.new-dot-home {
  width: 76px;
  height: 17px;
  background: linear-gradient(90deg, #54ebec 0%, #23b3f5 92.86%);
  border-radius: 30px;
}
.img-set {
  margin-right: 46px;
}
.decrease {
  max-width: 200px;
}
.changing-back {
  background: rgba(38, 187, 255, 0.1);
  background-image: url(/static/media/Doc.a6d73487.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
}

.Thoughts-back-img {
  background: url(/static/media/Geo.a2d9d73f.png),
    linear-gradient(180deg, #2b4f5e 0%, #209ccb 100%);
  margin-top: 85px;
  background-repeat: no-repeat;
  background-position: 13% 0;
}
.vector-img1 {
  left: -65px;
  position: absolute;
}
.vector-img2 {
  right: -60px;
  position: absolute;
}
.get-started-btn {
  padding: 2rem 0 0 0;
}
.authorization-img {
  text-align: right;
}
.group-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  max-width: 384px;
}
.moreTime {
  max-width: 341px;
}
.improve {
  max-width: 200px !important;
}
.get-free {
  font-size: 20px;
}
@media screen and (max-width: 1000px) {
  .group-col {
    max-width: 375px !important;
  }
  .groupRow {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .container-main {
    max-width: 100% !important;
    padding: 0 30px !important;
    padding-right: 30px !important;
  }
  .group-col:last-child {
    padding-left: 0;
  }
  .contain-text {
    font-size: 40px;
    line-height: 44px;
  }
  .contain-text-second {
    font-size: 17px;
    line-height: 26px;
  }
  .authorization-text {
    margin-left: 0 !important;
  }
  .vector-img1 {
    position: absolute;
  }
  .group-text {
    font-size: 20px;
  }
  .group-second-text {
    font-size: 16px;
  }
  .about-navbar .nav-navbar-text {
    color: #fff !important;
  }
}

.get-started {
  padding: 4rem 0 4rem 0;
}
.navbar-light .navbar-toggler {
  border: none !important;
  color: white;
}
.navbar-toggler .collapsed{
  margin-right:0 !important;
}
@media screen and (max-width: 960px) {
  .get-started {
    padding: 1rem 0 1rem 0;
  }
  .forever-text-home {
    font-size: 39px;
    line-height: 56px;
  }
}

@media screen and (max-width: 768px) {
  .img-set {
    margin-right: 0px;
  }
  .main-text-col {
    padding-right: 0 !important;
  }
  .img-set {
    margin-right: 0px;
  }
  .group-text {
    padding-right: 0px;
  }
  .forever-text {
    font-size: 32px;
    line-height: 36px;
  }
  .banner {
    background: linear-gradient(135deg, #3c8ce7 0%, #00eaff 100%);
  }
  .Thoughts-back-img {
    background: linear-gradient(180deg, #2b4f5e 0%, #209ccb 100%);
  }
  .new-dot-home {
    display: none;
  }
  .container-main {
    padding-right: 30px !important;
  }
  .authorization-row1 {
    text-align: center;
    padding-top: 74px;
  }
  .authorization-row2 {
    text-align: center;
    padding-top: 50px;
    flex-direction: column-reverse;
  }
  .authorization-img {
    margin-top: 20px;
  }
  .authorization-img img {
    width: 100%;
  }
  .thought-text {
    font-size: 36px;
  }
  .group-col {
    text-align: center;
  }
  .vector-img1 {
    width: 30px;
    height: 30px;
    position: unset;
  }
  .vector-img2 {
    width: 30px;
    height: 30px;
    position: unset;
  }
  .vector-img-main {
    display: flex;
    justify-content: center;
    margin: 30px 0;
  }
}
@media screen and (max-width: 600px) {
  .container-main {
    width: 100%;
    max-width: 100% !important;
    padding: 0 10px !important;
  }

  .getStartedDiv {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .forever-text-home {
    font-size: 32px;
    line-height: 36px;
  }

  .getStartedCol {
    max-width: 200px;
  }
  .btn-btn-get-home-bottom {
    padding-right: 45px !important;
    padding-left: 45px !important;
  }
  .changing-back {
    background-image: none;
  }

  .banner {
    padding: 100px 0;
  }
  .main-text {
    font-family: "Lato";
    font-size: 36px;
    line-height: 42px;
    text-align: center;
  }
  .mian-second-text {
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    padding-top: 39px;
  }
  .group-second-text {
    font-size: 15px;
    line-height: 20px;
  }
  .header-btn {
    text-align: center;
    padding-top: 39px;
  }
  .group-col {
    text-align: center;
    padding: 87px 30px 0 30px !important;
  }
  .contain-text {
    font-size: 32px;
    line-height: 36px;
  }
  .Thoughts-back-img {
    margin-top: 35px;
  }
  .contain-text-second {
    font-size: 17px;
    line-height: 22px;
  }
  .thought-text {
    font-size: 32px;
    padding: 30px 0px 0 0px;
    line-height: 36px;
  }
  .practice-text {
    font-size: 21px;
    line-height: 28px;
    padding-left: 30px;
    padding-right: 30px;
  }

  .group-col:last-child {
    padding-right: 30px;
    padding-left: 30px;
  }
  .group-text {
    padding-right: 0;
    font-size: 20px;
    line-height: 24px;
  }
  .authorization-row1 {
    padding: 74px 10px 0 10px;
    text-align: center;
  }
  .authorization-row2 {
    padding: 70px 10px 40px 10px;
    flex-direction: column-reverse;
    text-align: center;
  }
  .contain-text-second {
    margin-bottom: 15px;
    margin-top: 15px;
    padding: 0 10px;
  }
  /* .vector-img-main {
    display: flex;
    justify-content: center;
    margin: 30px 0;
  } */
  /* .vector-img1 {
    width: 30px;
    height: 30px;
    position: unset;
  }
  .vector-img2 {
    width: 30px;
    height: 30px;
    position: unset;
  } */
  .get-started-btn {
    text-align: center;
  }
  .forever-text {
    text-align: center;
    font-size: 32px;
    line-height: 36px;
    padding-left: 60px;
    padding-right: 60px;
  }
  .get-started {
    padding: 40px 0 !important;
  }
}

/* .modalHeaderRow {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
} */

.modal-name-text {
  display: flex !important;
  flex-direction: column !important;
}

.modal-heading-col {
  /* align-items: center;
  justify-content: center; */
  display: flex;
  flex-direction: column;
  align-items: inherit;
  justify-content: center;
}

.person-name {
  width: 160px;
  letter-spacing: -0.1px;
}
.person-name-modal {
  width: 100%;
  letter-spacing: -0.1px;
}

.btn-close {
  font-size: 11px;
}

.modalHeaderCol {
  max-width: 156px !important;
}

.modal-backdrop.show {
  width: 100%;
  height: 100%;
  /* max-width: 100vw; */
  /* max-width: 100vw; */
}
@media screen and (max-width: 415px) {
  .modal-heading-col {
    padding-right: 0 !important;
  }
  .modalHeaderCol {
    padding-right: 0 !important;
  }
}

@media screen and (max-width: 350px) {
  .btn-btn-get-home-bottom {
    padding: 15px !important;
  }
  .person-name {
    font-size: 21px !important;
    line-height: 29px;
  }
  .person-des {
    font-size: 19px !important;
  }
  .modal-heading-col {
    padding-left: 8px !important;
    align-items: flex-start;
  }
  .modalHeaderCol {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
@media screen and (max-width: 320px) {
  .person-name {
    font-size: 19px !important;
  }
  .person-des {
    font-size: 16px !important;
  }
  .modal-desc-text {
    font-size: 14px;
  }
  .modal-header .btn-close {
    padding: 0 !important;
  }
}

.variable-width {
    width: 70%;
    text-align: center;
    margin: 0 auto;
}
.slick-dots {
    position: static;
    position: initial;
    padding-bottom: 15px;
}
.sliderText{
    padding-top:10px;
    padding-bottom:10px;
}
@media screen and (max-width: 1000px) {
  .container-main {
    max-width: 100% !important;
    padding: 0 30px !important;
  }
  /* .navbar-collapse.show .buttonForm {
    position: relative;
  }
  .expanded-bg-header .nav-container-main {
    display: none;
  } */
  .nav-navbar{
    padding: 0!important;
  }
  .navbar-collapse.show {
    min-height: 100vh;
  }
  .nav-navbar-text {
    width: 100%;
  }
  .navbar-collapse {
    transition: none !important;
  }
  .buttonForm {
    display: none;
  }
}
.navbar-collapse {
  flex-grow: 0 !important;
}
.expanded-bg-header .nav-container-main {
  height: 100%;
}
.nav-container-main {
  position: relative;
  padding-top: 15px;
}
.nav-container-main .close-icon {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.nav-container-main .close-icon img {
  width: 41px;
  height: 46px;
}

.nav-navbar-text {
  padding-top: 5px;
  padding: 6px;
  padding-left: 0px;
}

.btn-text-name {
  font-family: Plus Jakarta Sans;
}

.groupRow {
  justify-content: center;
  align-items: CENTER;
  display: FLEX;
  flex-direction: ROW;
}

@media screen and (max-width: 769px) {
  .improve,
  .decrease,
  .moreTime {
    max-width: 100% !important;
  }
  .group-col {
    padding-top: 50px;
  }
  .navbar-toggler-icon {
    width: 24px;
    height: 24px;
  }
}

@media screen and (max-width: 992px) {
  .buttonForm {
    padding-top: 7px;
  }
}

@media screen and (max-width: 600px) {
  .nav-container-main {
    padding-left: 20px !important;
    padding-top: 0;
  }
  .group-col {
    padding: 40px 5px 40px 5px !important;
  }
  .groupRow:first-child {
    padding-top: 60px;
  }
}

@media screen and (max-width: 320px) {
  .buttonForm {
    padding-top: 18px;
  }
  .btn.btn-login {
    padding: 7px;
  }
  .btn-text-name {
    font-size: 15px;
  }
}
.expanded-bg-header {
  background: #1d4354;
  height: 100vh;
  padding: 2rem;
}

@media (max-width: 1200px) {
  .footer-col.col-md-9 {
    width: 60%;
  }
}
.mainContainer {
  padding: 64px 0px 64px 0px !important;
}
.text-group {
  padding: 1rem 0 0 0;
}

.footer-custom-group {
  padding: 1rem 0px 0px;
  display: flex;
  justify-content: space-between;
  width: 300px;
}
.company,
.product {
  margin-top: -30px !important;
}
@media screen and (max-width: 1000px) {
  .company,
  .product {
    margin-top: 0 !important;
  }
}

.rowCustom,
.column {
  flex: 0 0 auto !important;
  width: auto !important;
  flex-wrap: nowrap !important;
}
@media screen and (max-width: 768px) {
  .rowCustom,
  .column {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: center;
  }

  .com-text {
    letter-spacing: -0.1px;
  }
  .com-text-group {
    padding: 1rem 0px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .text-group {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .footer-col {
    position: relative;
  }
  .copyright-main-container {
    order: 5;
  }
  .copyRight-span {
    display: flex;
    flex-direction: column;
    display: flex;
    flex-direction: column-reverse;
  }
  .mainContainer {
    padding: 55px 0px 35px 0px !important;
  }
  .footerLogo {
    padding-bottom: 35px;
    display: flex;
    justify-content: center;
  }
  .product {
    padding-top: 28px;
  }
}

body {
  font-family: Plus Jakarta Sans;
}
.about-navbar-text {
  font-family: Plus Jakarta Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.1px;
  color: #111111;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 16px;
}
.about-btn-login,
.about-btn-login:hover {
  background: #26bbff;
  border-radius: 4px;
  border: none;
  align-items: flex-start;
  padding: 10px 16px;

  /* width: 78px;
  height: 40px; */
}
.about-btn-text-name {
  font-family: Plus Jakarta Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.1px;
  color: #ffffff;
  flex: none;
  order: 0;
  flex-grow: 0;
}
.about-text.aboutChange {
  font-family: Plus Jakarta Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 64px;
  text-align: center;
  letter-spacing: -0.1px;
  color: #111111;
  flex: none;
  order: 0;
  flex-grow: 0;
  padding-top: 64px;
}
.about-desc.aboutChangeDesc {
  font-family: Plus Jakarta Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 32px;
  text-align: center;
  letter-spacing: -0.1px;
  color: #444444;
  flex: none;
  order: 1;
  flex-grow: 0;
  padding-bottom: 30px;
}
.about-group-text {
  font-family: Plus Jakarta Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -0.1px;
  color: #111111;
  padding: 2rem 0 0 0;
}
.about-group-second-text {
  font-family: Plus Jakarta Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 24px;
  letter-spacing: -0.1px;
  color: #444444;
  padding: 12px 0 0 0;
}
.our-about-text1 {
  font-family: "Lato";
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 56px;
  text-align: center;
  letter-spacing: -0.1px;
  color: #111111;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 15px 0px;
  padding-top: 95px;
}
.our-about-desc.ourChange {
  font-family: Plus Jakarta Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  letter-spacing: -0.1px;
  color: #444444;
  padding-bottom: 64px;
}
.bio-name-text-about {
  font-family: Plus Jakarta Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -0.1px;
  color: #111111;
  display: flex;
  flex-direction: column;
  /* padding: 1rem 0 0 0; */
}
.desc-about-text {
  font-family: Plus Jakarta Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 24px;
  letter-spacing: -0.1px;
  color: #444444;
}
.board-text {
  font-family: Plus Jakarta Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 56px;
  letter-spacing: -0.1px;
  color: #111111;
  text-align: center;
  padding: 80px 0 0 0;
}
.board-bio-name-text {
  font-family: Plus Jakarta Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: -0.1px;
  color: #111111;
  padding: 2rem 0 0 0;
}
.board-desc-about-text {
  font-family: Plus Jakarta Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 24px;
  letter-spacing: -0.1px;
  color: #444444;
  padding-top: 0.5rem;
  /* opacity: 0.5; */
}
.bio-btn-text {
  font-family: Plus Jakarta Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.1px;
  color: #26bbff;
  padding: 24px 0 0 0;
  cursor: pointer;
}
.modal-name-text {
  font-family: Plus Jakarta Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -0.1px;
  color: #111111;
}
.modal-desc-text {
  font-family: Plus Jakarta Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 24px;
  letter-spacing: -0.1px;
  color: #444444;
}
.bio-desc {
  font-family: Plus Jakarta Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 24px;
  letter-spacing: -0.1px;
  color: #111111;
  padding-right: 30px;
  padding-left: 11px;
}
.modal-header {
  align-items: flex-start;
  border-bottom: none !important;
}
.modal-header .btn-close {
  color: #979797;
  -webkit-transform: matrix(-1, 0, 0, 1, 0, 0);
          transform: matrix(-1, 0, 0, 1, 0, 0);
}
.com-text {
  font-family: Plus Jakarta Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: 0.9px;
  color: #111111;
}
.about-group-col {
  padding-top: 2rem;
  text-align: center;
}
.bio-name-text-about {
  padding-top: 25px;
}
.personGroup {
  justify-content: flex-start;
}
.Img-desc {
  min-height: 160px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.Img-css {
  max-width: 264px;
  height: 352px;
}
.Img-css img {
  width: 100%;
  height: 100%;
  border-radius: 25px;
}

.modalImage img{
  border-radius: 12px;
}
@media only screen and (max-width: 992px) {
  .Img-desc {
    min-height: 215px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

@media only screen and (max-width: 575px) {
  .Img-main {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: center;
    padding: 0 0 0 1rem;
    width: 100%;
  }

  .bio-name-text-about {
    display: flex;
    flex-direction: column;
    padding-top: 0;
  }
  .Img-css {
    min-width: 143px;
    max-width: 143px;
    height: 189.33px;
    margin-right: 0.3rem;
  }
  .Img-desc {
    padding-left: 1rem;
    text-align: left;
    justify-content: end;
    min-height: auto;
  }
}




@media only screen and (max-width: 767px) {

  .Img-desc{
    min-height: 190px;
  }
  .personGroup {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .bio-name-text-about {
    font-size: 20px;
    line-height: 26px;
    font-family: Lato;
  }

  .about-text.aboutChange {
    font-family: lato;
    font-weight: 700;
    font-size: 36px;
    font-style: normal;
    align-items: center;
    line-height: 42px;
    color: #111111;
    margin: 40px 0px 0px 0px;
    padding: 0;
  }
  .about-desc.aboutChangeDesc {
    font-family: "Lato";
    font-weight: normal;
    line-height: 28px;
    font-size: 20px;
    font-style: normal;
    padding: 0px 19px 0px 20px;
    margin: 0px;
  }
  .about {
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    align-items: center;
  }
  .about-desc {
    display: block;
  }

  .board-text {
    padding-top: 20px;
    font-size: 30px;
    margin-bottom: -45px;
  }
  .about-desc:first-child {
    margin-top: 14px;
    margin-bottom: 30px;
  }
  .our-about-text1 {
    font-family: "Lato";
    font-weight: 700;
    font-size: 30px;
    font-style: normal;
    align-items: center;
    line-height: 56px;
    color: #111111;
    padding-bottom: 0px;
    margin-bottom: 0px;
    padding-top: 15px;
  }
  .our-about-desc.ourChange {
    font-family: "Lato";
    font-weight: normal;
    line-height: 28px;
    font-size: 20px;
    font-style: normal;
    padding-left: 7%;
    padding-right: 7%;
    padding-bottom: 0px;
  }

  .desc-about-text {
    font-family: Plus Jakarta sans-serif;
    font-weight: 400;
    font-size: 17px;
    font-style: normal;
    margin-top: 15px;
    letter-spacing: -0.1px;
  }
  .bio-btn-text {
    font-weight: 400;
  }
  .bio-btn-text span {
    border-bottom: 1px solid #26bbff;
  }
  .col-board {
    text-align: center;
  }

  .about-group-text {
    font-family: lato;
    line-height: 28.8px;
  }
  .about-group-second-text {
    font-family: lato;
    line-height: 24px;
    width: 75%;
  }
  .about-group-col {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  .board-bio-name-text {
    font-family: Lato;
    font-size: 22px;
    line-height: 28px;
    font-weight: 600;
    padding-top: 1.5rem;
  }
  .board-desc-about-text {
    font-size: 16px;
    line-height: 24px;
    font-family: Lato;
    width: 80%;
    margin: auto;
  }
  .col-board:first-child .board-desc-about-text {
    width: 70%;
  }
}

@media screen and (max-width: 550px) {
  .mainGroup {
    padding: 5rem 0px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

@media only screen and (max-width: 320px) {
  .Img-css {
    min-width: 110px;
    max-width: 110px;
  }
}
.linkedinIcon {
  margin-left: 10px;
  font-size: 20px;
}

.linkedinIcon a {
  color: inherit;
}

.galeHeadingCol {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5rem 0 0 0;
  align-items: center;
  justify-content: center;
}

.galeContainer {
  max-width: 1320px;
}
.galeHeading {
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 55px;
  line-height: 28px;
  /* identical to box height, or 51% */
  padding-top: 50px;
  padding-bottom: 30px;
  text-align: center;
  letter-spacing: -0.1px;
  display: flex;
  justify-content: center;
}
.galeSubHeading {
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 26px;
  line-height: 32px;
  /* or 123% */

  text-align: center;
  letter-spacing: -0.1px;
  padding-top: 20px;
  padding-bottom: 30px;
  color: #111111;
}

.galeBlueHeading {
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 26px;
  line-height: 32px;
  /* or 123% */

  text-align: center;
  letter-spacing: -0.1px;
  padding-bottom: 30px;

  color: #26bbff;
}

.galeParagraph {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 28px;
  /* or 140% */

  text-align: center;
  letter-spacing: -0.1px;

  color: #444444;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.galeParagraph p {
  max-width: 800px;
}

.galeBlueDiv {
  width: 799px;
  height: 225px;
  left: 433px;
  top: 685px;

  background: #eaf9ff;
  border-radius: 33px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.galeGradiant {
  width: 76px;
  height: 17px;
  left: 793px;
  top: 729px;

  background: linear-gradient(90deg, #54ebec 0%, #23b3f5 92.86%);
  border-radius: 30px;
}

.galeGradiantParagraph {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 26px;
  line-height: 32px;
  /* or 123% */

  text-align: center;
  letter-spacing: -0.1px;

  color: #111111;
  max-width: 600px;
  padding-top: 14px;
}

.galeGradiantSecPara {
  max-width: 629px;
  text-align: center;
}

.threeColumnsRow {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding-top: 45px;
}

.threeColumns {
  max-width: 245px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.threeColumnsHeading {
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 26px;
  line-height: 32px;
  /* or 123% */

  text-align: center;
  letter-spacing: -0.1px;

  color: #111111;
}
.threeColumnsImages {
  height: 90px;
}
.checkListSpan {
  display: flex;
  align-items: center;
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 33px;
}

.checkListDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: Lato;
  font-style: normal;
  font-weight: 800;
  font-size: 20px;
  line-height: 28px;
  /* or 140% */

  text-align: center;
  letter-spacing: -0.1px;

  color: #444444;
}
.rightVector {
  padding-right: 10px;
  padding-bottom: 10px;
}

.checkListHeading {
  font-family: Lato;
  font-style: normal;
  font-weight: 800;
  font-size: 20px;
  line-height: 28px;
  /* or 140% */

  text-align: center;
  letter-spacing: -0.1px;

  color: #444444;
}

.galeSolutions {
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 29px;
  /* or 145% */

  text-align: center;
  letter-spacing: -0.1px;

  color: #111111;
  max-width: 200px;
  min-width: 245px;
}
.galeSolutionsDiv {
  padding-right: 20px;
}

.galeSolutionsImage {
  min-height: 95px;
}
.galeSubHeadingShort {
  font-family: Lato;
  font-style: normal;
  font-weight: 500;
  font-size: 37px;
  line-height: 38px;
  /* or 103% */

  text-align: center;
  letter-spacing: -0.1px;
  max-width: 900px;
}
.galeCustomBtn {
  padding: 9px 18px 10px 25px !important;
}
.learnMore {
  font-size: 16px;
}

.galeTextContainer {
  max-width: 500px;
}
.textLeft {
  text-align: left;
  padding-top: 0px;
}

.galeRowContainer {
  display: flex;
  justify-content: center;
  padding: 160px 0 0 0;
}
.galeFocusPara {
  max-width: 615px;
  text-align: center;
}

.theSolution {
  font-family: Lato;
  font-style: normal;
  font-weight: 500;
  font-size: 37px;
  line-height: 56px;
  /* identical to box height, or 151% */

  letter-spacing: -0.1px;

  color: #111111;
}

.marginRight {
  margin-right: 50px;
}

.marginLeft {
  margin-left: 50px;
}

@media screen and (max-width: 1440px) {
  .ContainerMain {
    max-width: 100% !important;
  }
}

.about-navbar-text {
  font-family: Plus Jakarta Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.1px;
  color: #111111;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 16px;
}
.contact-btn-login,
.contact-btn-login:hover {
  background: #26bbff;
  border-radius: 4px;
  border: none;
  width: 78px;
  height: 40px;
}
.about-btn-text-name {
  font-family: Plus Jakarta Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.1px;
  color: #ffffff;
  flex: none;
  order: 0;
  flex-grow: 0;
}
.about-text {
  font-family: Plus Jakarta Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 56px;
  line-height: 64px;
  text-align: center;
  letter-spacing: -0.1px;
  color: #111111;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 24px 0px;
}
.about-desc {
  font-family: Plus Jakarta Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  letter-spacing: -0.1px;
  color: #444444;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 24px 0px;
  display: block;
}
/* .about-group-text {
  font-family: Plus Jakarta Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -0.1px;
  color: #111111;
  padding: 1rem 0 0 0;
}
.about-group-second-text {
  font-family: Plus Jakarta Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 24px;
  letter-spacing: -0.1px;
  color: #444444;
} */
.our-about-text {
  font-family: Plus Jakarta Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 56px;
  line-height: 64px;
  text-align: center;
  letter-spacing: -0.1px;
  color: #111111;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 24px 0px;
}
.our-about-desc {
  font-family: Plus Jakarta Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  letter-spacing: -0.1px;
  color: #444444;
}
.bio-name-text {
  font-family: Plus Jakarta Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -0.1px;
  color: #111111;
  padding: 1rem 0 0 0;
}
.desc-about-text {
  font-family: Plus Jakarta Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 24px;
  letter-spacing: -0.1px;
  color: #444444;
  max-width: 250px;
}
.contact-text {
  font-family: Plus Jakarta Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 56px;
  line-height: 64px;
  text-align: center;
  letter-spacing: -0.1px;
  color: #111111;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 24px 0px;
  padding: 64px 0 24px 0;
  padding-bottom: 0px;
  margin-bottom: 0px;
  /* padding: 64px 0 24px 0; */
}
.contact-text-second {
  font-family: Plus Jakarta Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  letter-spacing: -0.1px;
  color: #444444;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 24px 0px;
  padding-bottom: 25px !important;
}
@media (min-width: 1200px) {
  .contact-text-second {
    padding: 0 2rem;
    padding-bottom: 25px;
  }
  /* .form-wrapper {
    padding: 0 2rem;
  } */
}

.form-firstName {
  font-family: Plus Jakarta Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.1px;
  color: #111111;
}
.form-lastName {
  font-family: Plus Jakarta Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.1px;
  color: #111111;
}
.form-control-placeholder {
  font-family: Plus Jakarta Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.1px;
  color: #222222;
  mix-blend-mode: normal;
  flex: none;
  order: 0;
  flex-grow: 0;
  height: 56px;
  /* padding: 15px 0 15px 0; */
}
.form-control-placeholder1 {
  font-family: Plus Jakarta Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.1px;
  color: #222222;
  mix-blend-mode: normal;
  flex: none;
  order: 0;
  flex-grow: 0;
}
.form-email {
  font-family: Plus Jakarta Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.1px;
  color: #111111;
}
.form-title {
  font-family: Plus Jakarta Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.1px;
  color: #111111;
}
.form-company {
  font-family: Plus Jakarta Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.1px;
  color: #111111;
}
.form-mess {
  font-family: Plus Jakarta Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.1px;
  color: #111111;
}
.form-button,
.form-button:hover {
  background: #26bbff;
  border-radius: 4px;
  border: none;
}
.card-title {
  font-family: Plus Jakarta Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 56px;
  letter-spacing: -0.1px;
  color: #000000;
}
.card-text {
  font-family: Plus Jakarta Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 24px;
  letter-spacing: -0.1px;
  color: #444444;
}
.card-link {
  font-family: Plus Jakarta Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.1px;
  color: #26bbff;
}
.rights-text {
  font-family: Plus Jakarta Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #787878;
  padding: 1rem 0 0 0;
}
.com-text {
  font-family: Plus Jakarta Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -0.1px;
  color: #111111;
}
.ab-text {
  font-family: Plus Jakarta Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 32px;
  display: flex;
  align-items: center;
  letter-spacing: -0.1px;
  color: #444444;
}
.mapima {
  z-index: 1;
}
.cardima {
  top: 30px;
  left: 30px;
  z-index: 2;
}
.contact-btn-submit {
  background: #26bbff !important;
  border-radius: 4px;
  font-family: Plus Jakarta Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.1px;
  color: #ffffff;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0;
  border: none !important;
  padding: 16px 20px 16px 20px !important;
  margin-top: 10px;
}
.maps {
  width: 360px;
  height: 316px;
  background: #ffffff;
  border-radius: 12px;
  position: absolute;
  bottom: 8%;
  left: 8%;
}
.our-map {
  font-family: Plus Jakarta Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 56px;
  letter-spacing: -0.1px;
  color: #000000;
  padding-top: 48px;
  padding-left: 48px;
}
.address-map {
  font-family: Plus Jakarta Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 24px;
  letter-spacing: -0.1px;
  color: #444444;
  padding-top: 24px;
  padding-right: 10rem;
  padding-left: 48px;
}
.open-map {
  font-family: Plus Jakarta Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.1px;
  color: #26bbff;
  padding-top: 48px;
  padding-left: 48px;
}

.ifram {
  padding-top: 60px;
  display: flex;
}

iframe {
  display: none;
}

/* Breakpoints for map handling*/
@media screen and (max-width: 956px) {
  .maps {
    left: 100px;
    height: 250px;
    width: 240px;
  }
  .our-map {
    padding-top: 38px;
    padding-left: 15px;
    font-size: 33px;
  }
  .address-map {
    padding-top: 10px;
    padding-right: 1rem;
    padding-left: 16px;
    font-size: 15px;
  }
  .open-map {
    padding-top: 13px;
    padding-left: 18px;
    font-size: 15px;
  }
}

@media screen and (max-width: 400px) {
  .maps {
    left: 55px;
  }
}

@media screen and (max-width: 310px) {
  .maps {
    left: 30px;
  }
}

.col-contact-text {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.contact-Col,
.formContainerMain {
  max-width: 879px !important;
}
.textArea {
  height: 135px;
}
/* Breakpoint for Mobile screem*/

@media screen and (max-width: 768px) {
  .contact-text {
    font-family: "Lato";
    font-style: normal;
    font-weight: 800;
    font-size: 36px;
    line-height: 42px;
    padding-bottom: 0px;
  }
  .contact-text-second {
    padding: 5px;
  }

  .formContainerMain {
    padding: 0px 30px 30px 30px !important;
  }

  .contact-btn-submit {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
  }
  .ifram {
    padding-top: 30px;
  }
}
.errorMessage {
  color: red;
  padding-top: 20px;
}

.about-navbar-text {
  font-family: "Lato";
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.1px;
  color: #111111;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 16px;
}
.solution-btn-login,
.solution-btn-login:hover {
  background: #26bbff;
  border-radius: 4px;
  border: none;
  width: 78px;
  height: 40px;
}
.about-btn-text-name {
  font-family: "Lato";
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.1px;
  color: #ffffff;
  flex: none;
  order: 0;
  flex-grow: 0;
}
.about-text {
  font-family: "Lato";
  font-style: normal;
  font-weight: bold;
  font-size: 56px;
  line-height: 64px;
  text-align: center;
  letter-spacing: -0.1px;
  color: #111111;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 24px 0px;
}
.solution-text {
  font-family: "Lato";
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 64px;
  text-align: center;
  letter-spacing: -0.1px;
  color: #111111;
  padding: 1rem 0 88px 0px;
}
.solution-text-second {
  font-family: "Lato";
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  letter-spacing: -0.1px;
  color: #444444;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 24px 0px;
  padding-top: 40px;
  /* padding-right: 7rem;
  padding-left: 7rem; */
}
.solution-text-last-second {
  font-family: "Lato";
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  letter-spacing: -0.1px;
  color: #444444;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 24px 0px;
}
.solution-text-last {
  font-family: "Lato";
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 56px;
  text-align: center;
  letter-spacing: -0.1px;
  color: #111111;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 24px 0px;
}
.rights-text {
  font-family: "Lato";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #787878;
  padding: 1rem 0 0 0;
}
.com-text {
  font-family: "Lato";
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: 0.9px;
  color: #111111;
}
.ab-text {
  font-family: "Lato";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 32px;
  display: flex;
  align-items: center;
  letter-spacing: -0.1px;
  color: #444444;
}
.mapima {
  z-index: 1;
}
.cardima {
  top: 30px;
  left: 30px;
  z-index: 2;
}
.solution-banner-image {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  /* position: absolute; */
  width: 100%;
  height: 424px;
  left: 0px;
  top: 484px;
  background: linear-gradient(135deg, #3c8ce7 0%, #00eaff 100%);
}
.solution-banner-text {
  font-family: "Lato";
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 56px;
  letter-spacing: -0.1px;
  color: #ffffff;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 10px;
}
.back-cover {
  background: linear-gradient(135deg, #3c8ce7e0 0%, #00eaffb8 100%),
    url(/static/media/banner01.0c099c71.jpg);
  font-size: 50px;
  /* padding: 128px 0 128px; */
  font-family: "Lato";
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 56px;
  letter-spacing: -0.1px;
  color: #ffffff;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.back-cover-col {
  max-width: 1080px;
}
/* .solution-text-by {
  font-family: "Lato";
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 56px;
  text-align: center;
  letter-spacing: -0.1px;
  color: #111111;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 24px 0px;
} */
.solution-text-natural {
  font-family: "Lato";
  font-style: normal;
  font-weight: bold;
  font-size: 56px;
  line-height: 64px;
  text-align: center;
  letter-spacing: -0.1px;
  color: #111111;
  flex: none;
  order: 0;
  flex-grow: 0;
  padding-top: 88px;
}

.solution-text-natural-secondary {
  display: flex;
  justify-content: center;
  padding: 0 px;
  font-size: 40px;
  font-weight: 800;
  padding-top: 10 px;
  box-sizing: border-box;
}

.backFirstPara {
  font-size: 48px;
  line-height: 56px;
}
@media (min-width: 1200px) {
  .solution-text-natural {
    padding: 2rem 0;
    padding-top: 88px;
    padding-bottom: 0.5rem;
  }
  .solution-text {
    padding: 2rem 0;
    padding-bottom: 88px;
    padding-top: 0px;
  }
  .solution-text-second {
    padding: 2rem 0;
    padding-bottom: 0px;
    padding-top: 88px;
  }
}
.solution-text-second-natural {
  font-family: "Lato";
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  letter-spacing: -0.1px;
  color: #444444;
  flex: none;
  order: 1;
  flex-grow: 0;
  /* margin: 24px 0px; */
  padding-bottom: 88px;
  padding-top: 24px;
  margin-top: 1.4rem;
  max-width: 770px;
}
.solution-text-everyone {
  font-family: "Lato";
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 56px;
  text-align: center;
  letter-spacing: -0.1px;
  color: #111111;
  flex: none;
  order: 1;
  flex-grow: 0;
  padding-top: 24px;
  padding-bottom: 88px;
}

@media screen and (max-width: 768px) {
  .solutionMainCol {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 5px 35px 35px 35px !important;
  }
  .solution-text-natural {
    font-size: 36px;
    line-height: 42px;
    text-align: left;
    margin-top: 20px;
  }

  .back-cover {
    padding: 100px 30px 100px 35px !important;
  }
  .solution-text-second-natural {
    text-align: left;
    padding-bottom: 25px;
    padding-top: 5px;
  }
  .backFirstPara {
    /* padding-left: 50px;
    padding-right: 50px; */
    font-size: 38px;
    line-height: 50px;
  }
  .solution-text-everyone {
    font-size: 30px;
    line-height: 45px;
    padding-bottom: 65px;
  }
}
.custom-button {
  padding: 50px 0 30px 0;
}

.blog-head-main-row {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.blog-head-main {
  margin-top: 139px;
  max-width: 1025px;
}
.blog-main {
  padding-right: 70px !important;
  /* padding-left: 0px !important; */
  margin-left: -10px;
}
.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
}

.active-page a {
  background: #26bbff;
  cursor: pointer;
  border-radius: 7px;
  color: white;
  width: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.page-item a {
  width: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.page-item a {
  border-radius: 7px;
}
.page-link {
  border-radius: 7px !important;
  cursor: pointer;
  color: rgb(151, 151, 151);
  border: none;
}
.page-link:hover {
  background: #26bbff;
}

.blog-group-text {
  font-family: Lato;
  font-style: normal;
  font-weight: 500;
  font-size: 21px;
  line-height: 24px;
  letter-spacing: -0.1px;
  color: #111111;
  padding-top: 19px;
}
.blog-group-second-text {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.1px;
  color: #636363;
  padding-top: 7px;
}
.blog-featured {
  font-family: Lato;
  font-style: normal;
  font-weight: 500;
  font-size: 21px;
  line-height: 24px;
  letter-spacing: -0.1px;
  color: #111111;
}
.blog-featured-second {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.1px;
  color: #636363;
  padding-top: 11px;
  border-bottom: 1px solid #d8d8d8;
  padding-bottom: 27px;
}
.blog-featured-second-two {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.1px;
  color: #636363;
  padding-top: 36px;
  border-bottom: 1px solid #d8d8d8;
  padding-bottom: 31px;
}

.blog-text-title {
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 56px;
  /* identical to box height, or 117% */

  text-align: center;
  letter-spacing: -0.1px;

  color: #111111;
}
.blog-text-second-title {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  /* or 125% */

  text-align: center;
  letter-spacing: -0.1px;

  color: #636363;
  padding-top: 18px;
  padding-bottom: 35px;
}
.part-contain {
  width: calc(100% - 15px);
  height: 426px;
  background: #eaf9ff;
  border-radius: 5px;
  margin: 113px 0 0 15px;
  background-image: url(/static/media/blogplayimg.4045b55b.png);
  background-repeat: no-repeat;
  background-position: 10px;
}
.forever-text {
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 30px;
  /* or 107% */

  letter-spacing: -0.1px;

  color: #111111;
  padding-top: 48px;
}
.btn-btn-get-blog.btn-outline-success,
.btn-btn-get-blog,
.btn-btn-get-blog:hover,
.btn-btn-get-blog.btn-outline-success:hover {
  background: #26bbff;
  width: 207px;
  height: 47px;
  border: none;
  /* or 150% */

  color: #ffffff;
}
.get-text-blog {
  border-radius: 4px;
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 16 px;
  line-height: 24px;
}
.blog-play-img {
  padding-top: 64px;
}
/* .blog-row {
  margin-left: -65px;
} */
.blog-container {
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-items: center !important;
  max-width: 100% !important;
}
.blogMain {
  display: flex;
  /* flex-direction: column; */
  max-width: 1320px;
}

.up-blog-img {
  cursor: pointer;
}

.sidebar-container {
  max-width: 390px;
  min-width: 390px;
}
@media screen and (max-width: 1200px) {
  .blog-container {
    max-width: 920px;
  }
  .blog-row {
    flex-direction: column;
    grid-gap: 20px;
    gap: 20px;
  }
  .part-contain {
    width: 100%;
  }
  .sidebar-container,
  .sidebar {
    max-width: 300px;
  }
}

@media screen and (max-width: 768px) {
  .up-blog-img {
    max-width: 100%;
  }
  .blogDataCol {
    min-width: 900px;
  }
  .sidebar,
  .sidebar-container,
  .part-contain {
    display: none !important;
  }
  .blog-container {
    max-width: 100% !important;
    padding: 0 30px;
    padding-bottom: 50px;
  }
  .blog-main {
    margin: 0;
    padding: 0 !important;
  }

  .sidebar {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .part-contain {
    width: 100%;
    margin: 113px 0 80px 0px;
    padding: 25px;
  }
  .blog-play-img {
    width: 25%;
    padding-top: 70px;
  }
}
@media screen and (max-width: 600px) {
  .blog-head-main {
    margin-top: 100px;
  }
  .blog-main {
    margin-bottom: 0 !important;
    padding: 30px 10px !important;
  }
  .blog-row {
    flex-direction: column;
    grid-gap: 20px;
    gap: 20px;
  }
  .blog-text-second-title {
    padding-bottom: 20px;
    padding-left: 20px;
    padding-right: 20px;
  }

  .blog-text-title {
    font-size: 34px;
  }
}

.imageGroup {
  position: relative;
}
.blogGradiant {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #0077c8 100%);
  border-radius: 12px;
  position: absolute;
  width: 100%;
  height: 100%;
}

.blog-row {
  max-width: 1860px;
}

.up-blog-img-single {
  width: 880px;
  max-width: 880px;
}

.gradiantHeadingText {
  display: flex;
  height: 100%;
  align-items: flex-end;
  padding-bottom: 30px;
  font-family: Lato;
  font-weight: 500;
  font-size: 34px;
  line-height: 41px;
  letter-spacing: -0.1px;
  color: #ffffff;
  padding-left: 40px;
}

.singleBlog {
  padding-bottom: 20px;
  padding-top: 20px;
}

.blogImage {
  max-height: 219px;
  border-radius: 22px;
}

.fullBodyImg {
  border-radius: 22px;
}

@media screen and (max-width: 1320px) {
  .up-blog-img-single {
    width: 100%;
    max-width: 880px;
  }
}

@media screen and (max-width: 768px) {
  .up-blog-img-single {
    width: 880px;
  }
}


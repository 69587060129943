.about-navbar-text {
  font-family: Plus Jakarta Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.1px;
  color: #111111;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 16px;
}
.contact-btn-login,
.contact-btn-login:hover {
  background: #26bbff;
  border-radius: 4px;
  border: none;
  width: 78px;
  height: 40px;
}
.about-btn-text-name {
  font-family: Plus Jakarta Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.1px;
  color: #ffffff;
  flex: none;
  order: 0;
  flex-grow: 0;
}
.about-text {
  font-family: Plus Jakarta Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 56px;
  line-height: 64px;
  text-align: center;
  letter-spacing: -0.1px;
  color: #111111;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 24px 0px;
}
.about-desc {
  font-family: Plus Jakarta Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  letter-spacing: -0.1px;
  color: #444444;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 24px 0px;
  display: block;
}
/* .about-group-text {
  font-family: Plus Jakarta Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -0.1px;
  color: #111111;
  padding: 1rem 0 0 0;
}
.about-group-second-text {
  font-family: Plus Jakarta Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 24px;
  letter-spacing: -0.1px;
  color: #444444;
} */
.our-about-text {
  font-family: Plus Jakarta Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 56px;
  line-height: 64px;
  text-align: center;
  letter-spacing: -0.1px;
  color: #111111;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 24px 0px;
}
.our-about-desc {
  font-family: Plus Jakarta Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  letter-spacing: -0.1px;
  color: #444444;
}
.bio-name-text {
  font-family: Plus Jakarta Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -0.1px;
  color: #111111;
  padding: 1rem 0 0 0;
}
.desc-about-text {
  font-family: Plus Jakarta Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 24px;
  letter-spacing: -0.1px;
  color: #444444;
  max-width: 250px;
}
.contact-text {
  font-family: Plus Jakarta Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 56px;
  line-height: 64px;
  text-align: center;
  letter-spacing: -0.1px;
  color: #111111;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 24px 0px;
  padding: 64px 0 24px 0;
  padding-bottom: 0px;
  margin-bottom: 0px;
  /* padding: 64px 0 24px 0; */
}
.contact-text-second {
  font-family: Plus Jakarta Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  letter-spacing: -0.1px;
  color: #444444;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 24px 0px;
  padding-bottom: 25px !important;
}
@media (min-width: 1200px) {
  .contact-text-second {
    padding: 0 2rem;
    padding-bottom: 25px;
  }
  /* .form-wrapper {
    padding: 0 2rem;
  } */
}

.form-firstName {
  font-family: Plus Jakarta Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.1px;
  color: #111111;
}
.form-lastName {
  font-family: Plus Jakarta Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.1px;
  color: #111111;
}
.form-control-placeholder {
  font-family: Plus Jakarta Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.1px;
  color: #222222;
  mix-blend-mode: normal;
  flex: none;
  order: 0;
  flex-grow: 0;
  height: 56px;
  /* padding: 15px 0 15px 0; */
}
.form-control-placeholder1 {
  font-family: Plus Jakarta Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.1px;
  color: #222222;
  mix-blend-mode: normal;
  flex: none;
  order: 0;
  flex-grow: 0;
}
.form-email {
  font-family: Plus Jakarta Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.1px;
  color: #111111;
}
.form-title {
  font-family: Plus Jakarta Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.1px;
  color: #111111;
}
.form-company {
  font-family: Plus Jakarta Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.1px;
  color: #111111;
}
.form-mess {
  font-family: Plus Jakarta Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.1px;
  color: #111111;
}
.form-button,
.form-button:hover {
  background: #26bbff;
  border-radius: 4px;
  border: none;
}
.card-title {
  font-family: Plus Jakarta Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 56px;
  letter-spacing: -0.1px;
  color: #000000;
}
.card-text {
  font-family: Plus Jakarta Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 24px;
  letter-spacing: -0.1px;
  color: #444444;
}
.card-link {
  font-family: Plus Jakarta Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.1px;
  color: #26bbff;
}
.rights-text {
  font-family: Plus Jakarta Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #787878;
  padding: 1rem 0 0 0;
}
.com-text {
  font-family: Plus Jakarta Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -0.1px;
  color: #111111;
}
.ab-text {
  font-family: Plus Jakarta Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 32px;
  display: flex;
  align-items: center;
  letter-spacing: -0.1px;
  color: #444444;
}
.mapima {
  z-index: 1;
}
.cardima {
  top: 30px;
  left: 30px;
  z-index: 2;
}
.contact-btn-submit {
  background: #26bbff !important;
  border-radius: 4px;
  font-family: Plus Jakarta Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.1px;
  color: #ffffff;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0;
  border: none !important;
  padding: 16px 20px 16px 20px !important;
  margin-top: 10px;
}
.maps {
  width: 360px;
  height: 316px;
  background: #ffffff;
  border-radius: 12px;
  position: absolute;
  bottom: 8%;
  left: 8%;
}
.our-map {
  font-family: Plus Jakarta Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 56px;
  letter-spacing: -0.1px;
  color: #000000;
  padding-top: 48px;
  padding-left: 48px;
}
.address-map {
  font-family: Plus Jakarta Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 24px;
  letter-spacing: -0.1px;
  color: #444444;
  padding-top: 24px;
  padding-right: 10rem;
  padding-left: 48px;
}
.open-map {
  font-family: Plus Jakarta Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.1px;
  color: #26bbff;
  padding-top: 48px;
  padding-left: 48px;
}

.ifram {
  padding-top: 60px;
  display: flex;
}

iframe {
  display: none;
}

/* Breakpoints for map handling*/
@media screen and (max-width: 956px) {
  .maps {
    left: 100px;
    height: 250px;
    width: 240px;
  }
  .our-map {
    padding-top: 38px;
    padding-left: 15px;
    font-size: 33px;
  }
  .address-map {
    padding-top: 10px;
    padding-right: 1rem;
    padding-left: 16px;
    font-size: 15px;
  }
  .open-map {
    padding-top: 13px;
    padding-left: 18px;
    font-size: 15px;
  }
}

@media screen and (max-width: 400px) {
  .maps {
    left: 55px;
  }
}

@media screen and (max-width: 310px) {
  .maps {
    left: 30px;
  }
}

.col-contact-text {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.contact-Col,
.formContainerMain {
  max-width: 879px !important;
}
.textArea {
  height: 135px;
}
/* Breakpoint for Mobile screem*/

@media screen and (max-width: 768px) {
  .contact-text {
    font-family: "Lato";
    font-style: normal;
    font-weight: 800;
    font-size: 36px;
    line-height: 42px;
    padding-bottom: 0px;
  }
  .contact-text-second {
    padding: 5px;
  }

  .formContainerMain {
    padding: 0px 30px 30px 30px !important;
  }

  .contact-btn-submit {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
  }
  .ifram {
    padding-top: 30px;
  }
}
.errorMessage {
  color: red;
  padding-top: 20px;
}

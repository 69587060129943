.about-navbar-text {
  font-family: "Lato";
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.1px;
  color: #111111;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 16px;
}
.solution-btn-login,
.solution-btn-login:hover {
  background: #26bbff;
  border-radius: 4px;
  border: none;
  width: 78px;
  height: 40px;
}
.about-btn-text-name {
  font-family: "Lato";
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.1px;
  color: #ffffff;
  flex: none;
  order: 0;
  flex-grow: 0;
}
.about-text {
  font-family: "Lato";
  font-style: normal;
  font-weight: bold;
  font-size: 56px;
  line-height: 64px;
  text-align: center;
  letter-spacing: -0.1px;
  color: #111111;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 24px 0px;
}
.solution-text {
  font-family: "Lato";
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 64px;
  text-align: center;
  letter-spacing: -0.1px;
  color: #111111;
  padding: 1rem 0 88px 0px;
}
.solution-text-second {
  font-family: "Lato";
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  letter-spacing: -0.1px;
  color: #444444;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 24px 0px;
  padding-top: 40px;
  /* padding-right: 7rem;
  padding-left: 7rem; */
}
.solution-text-last-second {
  font-family: "Lato";
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  letter-spacing: -0.1px;
  color: #444444;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 24px 0px;
}
.solution-text-last {
  font-family: "Lato";
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 56px;
  text-align: center;
  letter-spacing: -0.1px;
  color: #111111;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 24px 0px;
}
.rights-text {
  font-family: "Lato";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #787878;
  padding: 1rem 0 0 0;
}
.com-text {
  font-family: "Lato";
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: 0.9px;
  color: #111111;
}
.ab-text {
  font-family: "Lato";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 32px;
  display: flex;
  align-items: center;
  letter-spacing: -0.1px;
  color: #444444;
}
.mapima {
  z-index: 1;
}
.cardima {
  top: 30px;
  left: 30px;
  z-index: 2;
}
.solution-banner-image {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  /* position: absolute; */
  width: 100%;
  height: 424px;
  left: 0px;
  top: 484px;
  background: linear-gradient(135deg, #3c8ce7 0%, #00eaff 100%);
}
.solution-banner-text {
  font-family: "Lato";
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 56px;
  letter-spacing: -0.1px;
  color: #ffffff;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 10px;
}
.back-cover {
  background: linear-gradient(135deg, #3c8ce7e0 0%, #00eaffb8 100%),
    url("../../image/banner01.jpg");
  font-size: 50px;
  /* padding: 128px 0 128px; */
  font-family: "Lato";
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 56px;
  letter-spacing: -0.1px;
  color: #ffffff;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.back-cover-col {
  max-width: 1080px;
}
/* .solution-text-by {
  font-family: "Lato";
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 56px;
  text-align: center;
  letter-spacing: -0.1px;
  color: #111111;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 24px 0px;
} */
.solution-text-natural {
  font-family: "Lato";
  font-style: normal;
  font-weight: bold;
  font-size: 56px;
  line-height: 64px;
  text-align: center;
  letter-spacing: -0.1px;
  color: #111111;
  flex: none;
  order: 0;
  flex-grow: 0;
  padding-top: 88px;
}

.solution-text-natural-secondary {
  display: flex;
  justify-content: center;
  padding: 0 px;
  font-size: 40px;
  font-weight: 800;
  padding-top: 10 px;
  box-sizing: border-box;
}

.backFirstPara {
  font-size: 48px;
  line-height: 56px;
}
@media (min-width: 1200px) {
  .solution-text-natural {
    padding: 2rem 0;
    padding-top: 88px;
    padding-bottom: 0.5rem;
  }
  .solution-text {
    padding: 2rem 0;
    padding-bottom: 88px;
    padding-top: 0px;
  }
  .solution-text-second {
    padding: 2rem 0;
    padding-bottom: 0px;
    padding-top: 88px;
  }
}
.solution-text-second-natural {
  font-family: "Lato";
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  letter-spacing: -0.1px;
  color: #444444;
  flex: none;
  order: 1;
  flex-grow: 0;
  /* margin: 24px 0px; */
  padding-bottom: 88px;
  padding-top: 24px;
  margin-top: 1.4rem;
  max-width: 770px;
}
.solution-text-everyone {
  font-family: "Lato";
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 56px;
  text-align: center;
  letter-spacing: -0.1px;
  color: #111111;
  flex: none;
  order: 1;
  flex-grow: 0;
  padding-top: 24px;
  padding-bottom: 88px;
}

@media screen and (max-width: 768px) {
  .solutionMainCol {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 5px 35px 35px 35px !important;
  }
  .solution-text-natural {
    font-size: 36px;
    line-height: 42px;
    text-align: left;
    margin-top: 20px;
  }

  .back-cover {
    padding: 100px 30px 100px 35px !important;
  }
  .solution-text-second-natural {
    text-align: left;
    padding-bottom: 25px;
    padding-top: 5px;
  }
  .backFirstPara {
    /* padding-left: 50px;
    padding-right: 50px; */
    font-size: 38px;
    line-height: 50px;
  }
  .solution-text-everyone {
    font-size: 30px;
    line-height: 45px;
    padding-bottom: 65px;
  }
}
.custom-button {
  padding: 50px 0 30px 0;
}

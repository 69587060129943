@media (min-width: 1400px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1220px;
  }
}

body {
  font-family: "Lato";
}
.logo-image {
  width: 116px;
  height: 20px;
}
.bg-header {
  background: linear-gradient(135deg, #3c8ce7 0%, #00eaff 100%);
}
.header-btn {
  padding-top: 15px;
}
img {
  max-width: 100%;
}
.banner {
  padding: 100px;
  padding-left: 5px;
}
.main-text {
  font-family: "Lato";
  font-style: normal;
  font-weight: bold;
  font-size: 58px;
  line-height: 64px;
  letter-spacing: -0.1px;
  color: #ffffff;
  padding: 2rem 0 0 0;
}
.btn.btn-login,
.btn.btn-login:hover {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 10px 16px !important;
  background: #ffffff;
  border-radius: 4px;
  color: #26bbff;
  border-color: white;
  padding: 10px 20px 10px;
}
.about-navbar .btn.btn-login {
  background: #26bbff;
  border-color: #26bbff;
  min-width: 90px;
  justify-content: center;
}
.about-navbar .btn.btn-login span {
  color: #fff !important;
}
.about-navbar.bg-header .btn.btn-login {
  background: #fff;
  border-color: #fff;
}
.about-navbar.bg-header .btn.btn-login span {
  color: #26bbff !important;
}
.about-navbar .white-logo {
  display: block;
}
.about-navbar .color-logo {
  display: none;
}
.about-navbar.bg-header .white-logo {
  display: none;
}
.about-navbar.bg-header .color-logo {
  display: block;
}
.expanded-bg-header .white-logo {
  display: block;
}
.expanded-bg-header .color-logo {
  display: none;
}

.nav-navbar-text.active-nav {
  border-bottom: 3px solid #111111;
  padding-bottom: 5px;
}
.about-navbar.bg-header .nav-navbar-text.active-nav {
  border-color: #fff;
}
.expanded-bg-header .navbar-nav {
  padding-top: 1rem;
}
.expanded-bg-header .nav-navbar-text {
  padding: 1rem 0;
  margin: 0;
}
.nav-navbar-text {
  font-family: "Lato";
  font-style: normal;
  font-weight: 500 !important;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.1px;
  color: #ffffff !important;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 16px;
  text-decoration: none;
}
.btn-text-name {
  font-family: "Lato";
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.1px;
  color: #26bbff;
  flex: none;
  order: 0;
  flex-grow: 0;
}
.banner {
  background: url("../../image/home.png"),
    linear-gradient(135deg, #3c8ce7 0%, #00eaff 100%);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
}
.mian-second-text {
  font-family: "Lato";
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -0.1px;
  color: #ffffff;
  line-height: 2;
  padding: 1rem 0 0 0;
}

.group-text {
  font-family: "Lato";
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -0.1px;
  color: #111111;
  padding: 1rem 0 0 0;
  padding-right: 3rem;
}
.group-second-text {
  font-family: "Lato";
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 24px;
  letter-spacing: -0.1px;
  color: #444444;
  padding: 12px 0 0 0;
}
.group-col {
  padding-top: 97px;
  padding-left: 25px;
  padding-right: 25px;
}
.group-col:last-child {
  padding-right: 0px;
  padding-left: 40px;
}

.authorization-row1 {
  padding: 160px 0 0 0;
}
.authorization-row2 {
  padding: 70px 0 0 0;
}
.contain-image {
  border-radius: 12px;
}
.contain-text {
  font-family: "Lato";
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 56px;
  letter-spacing: -0.1px;
  color: #111111;
  padding-top: 18px;
}
.contain-text-second {
  font-family: "Lato";
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: -0.1px;
  color: #444444;
  padding: 25px 0 0 0;
}
.thought-text {
  font-family: "Lato";
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 56px;
  text-align: center;
  letter-spacing: 0.6px;
  color: #fff;
  margin-bottom: 0.7rem;
}
.practice-text {
  font-family: "Lato";
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  letter-spacing: 0.6px;
  color: #fff;
  padding: 1% 0 0 0;
  min-height: 70px;
}
.General-text {
  font-family: "Lato";
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: -0.1px;
  color: #fff;
  text-align: center;
  padding: 1% 0 51px 0;
  margin-top: 40px;
}
.get-text {
  font-family: Plus Jakarta Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  color: #ffffff;
}
.btn-btn-get.btn-outline-success,
.btn-btn-get,
.btn-btn-get:hover,
.btn-btn-get.btn-outline-success:hover {
  background: #26bbff;
  border-radius: 4px;
  border: none;
  font-family: "Lato";
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.3px;
  color: #ffffff;
  padding: 1rem 2rem 1rem 2rem !important;
}
.btn-btn-get-home-bottom {
  padding: 20px 54px 20px 30px !important;
  background: #26bbff !important;
  color: white !important;
  border: none !important;
}
.btn-check:focus + .btn-outline-success,
.btn-outline-success:focus {
  box-shadow: none;
}
.forever-text-home {
  font-family: Plus Jakarta Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 56px;
  letter-spacing: -0.1px;
}
.rights-text {
  font-family: "Lato";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #787878;
  padding: 1rem 0 0 0;
}
.com-text {
  font-family: "Lato";
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -0.1px;
  color: #111111;
}
.ab-text {
  font-family: "Lato";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 32px;
  display: flex;
  align-items: center;
  letter-spacing: -0.1px;
  color: #444444;
}
.about-navbar .nav-navbar-text {
  font-family: "Lato";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.1px;
  color: #111111 !important;
  flex: none;
  order: 0;
  flex-grow: 0;
  text-decoration: none;
}

.about-navbar.bg-header .nav-navbar-text {
  color: #fff !important;
}

.modal-content {
  border-radius: 12px !important;
}
.modal-header .btn-close {
  margin: 0px 0px 7rem 0 !important;
}
.btn-btn-get-home.btn-outline-success,
.btn-btn-get-home,
.btn-btn-get-home:hover,
.btn-btn-get-home.btn-outline-success:hover {
  background: #1d4354;
  border-radius: 4px;
  border: none;
  font-family: "Lato";
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.3px;
  color: #ffffff;
  padding: 1rem 2rem 1rem 2rem !important;
}
.new-dot-home-col {
  margin-top: -30px !important;
}
.new-dot-home {
  width: 76px;
  height: 17px;
  background: linear-gradient(90deg, #54ebec 0%, #23b3f5 92.86%);
  border-radius: 30px;
}
.img-set {
  margin-right: 46px;
}
.decrease {
  max-width: 200px;
}
.changing-back {
  background: rgba(38, 187, 255, 0.1);
  background-image: url(../../image/Doc.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
}

.Thoughts-back-img {
  background: url(../../image/Geo.png),
    linear-gradient(180deg, #2b4f5e 0%, #209ccb 100%);
  margin-top: 85px;
  background-repeat: no-repeat;
  background-position: 13% 0;
}
.vector-img1 {
  left: -65px;
  position: absolute;
}
.vector-img2 {
  right: -60px;
  position: absolute;
}
.get-started-btn {
  padding: 2rem 0 0 0;
}
.authorization-img {
  text-align: right;
}
.group-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  max-width: 384px;
}
.moreTime {
  max-width: 341px;
}
.improve {
  max-width: 200px !important;
}
.get-free {
  font-size: 20px;
}
@media screen and (max-width: 1000px) {
  .group-col {
    max-width: 375px !important;
  }
  .groupRow {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .container-main {
    max-width: 100% !important;
    padding: 0 30px !important;
    padding-right: 30px !important;
  }
  .group-col:last-child {
    padding-left: 0;
  }
  .contain-text {
    font-size: 40px;
    line-height: 44px;
  }
  .contain-text-second {
    font-size: 17px;
    line-height: 26px;
  }
  .authorization-text {
    margin-left: 0 !important;
  }
  .vector-img1 {
    position: absolute;
  }
  .group-text {
    font-size: 20px;
  }
  .group-second-text {
    font-size: 16px;
  }
  .about-navbar .nav-navbar-text {
    color: #fff !important;
  }
}

.get-started {
  padding: 4rem 0 4rem 0;
}
.navbar-light .navbar-toggler {
  border: none !important;
  color: white;
}
.navbar-toggler .collapsed{
  margin-right:0 !important;
}
@media screen and (max-width: 960px) {
  .get-started {
    padding: 1rem 0 1rem 0;
  }
  .forever-text-home {
    font-size: 39px;
    line-height: 56px;
  }
}

@media screen and (max-width: 768px) {
  .img-set {
    margin-right: 0px;
  }
  .main-text-col {
    padding-right: 0 !important;
  }
  .img-set {
    margin-right: 0px;
  }
  .group-text {
    padding-right: 0px;
  }
  .forever-text {
    font-size: 32px;
    line-height: 36px;
  }
  .banner {
    background: linear-gradient(135deg, #3c8ce7 0%, #00eaff 100%);
  }
  .Thoughts-back-img {
    background: linear-gradient(180deg, #2b4f5e 0%, #209ccb 100%);
  }
  .new-dot-home {
    display: none;
  }
  .container-main {
    padding-right: 30px !important;
  }
  .authorization-row1 {
    text-align: center;
    padding-top: 74px;
  }
  .authorization-row2 {
    text-align: center;
    padding-top: 50px;
    flex-direction: column-reverse;
  }
  .authorization-img {
    margin-top: 20px;
  }
  .authorization-img img {
    width: 100%;
  }
  .thought-text {
    font-size: 36px;
  }
  .group-col {
    text-align: center;
  }
  .vector-img1 {
    width: 30px;
    height: 30px;
    position: unset;
  }
  .vector-img2 {
    width: 30px;
    height: 30px;
    position: unset;
  }
  .vector-img-main {
    display: flex;
    justify-content: center;
    margin: 30px 0;
  }
}
@media screen and (max-width: 600px) {
  .container-main {
    width: 100%;
    max-width: 100% !important;
    padding: 0 10px !important;
  }

  .getStartedDiv {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .forever-text-home {
    font-size: 32px;
    line-height: 36px;
  }

  .getStartedCol {
    max-width: 200px;
  }
  .btn-btn-get-home-bottom {
    padding-right: 45px !important;
    padding-left: 45px !important;
  }
  .changing-back {
    background-image: none;
  }

  .banner {
    padding: 100px 0;
  }
  .main-text {
    font-family: "Lato";
    font-size: 36px;
    line-height: 42px;
    text-align: center;
  }
  .mian-second-text {
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    padding-top: 39px;
  }
  .group-second-text {
    font-size: 15px;
    line-height: 20px;
  }
  .header-btn {
    text-align: center;
    padding-top: 39px;
  }
  .group-col {
    text-align: center;
    padding: 87px 30px 0 30px !important;
  }
  .contain-text {
    font-size: 32px;
    line-height: 36px;
  }
  .Thoughts-back-img {
    margin-top: 35px;
  }
  .contain-text-second {
    font-size: 17px;
    line-height: 22px;
  }
  .thought-text {
    font-size: 32px;
    padding: 30px 0px 0 0px;
    line-height: 36px;
  }
  .practice-text {
    font-size: 21px;
    line-height: 28px;
    padding-left: 30px;
    padding-right: 30px;
  }

  .group-col:last-child {
    padding-right: 30px;
    padding-left: 30px;
  }
  .group-text {
    padding-right: 0;
    font-size: 20px;
    line-height: 24px;
  }
  .authorization-row1 {
    padding: 74px 10px 0 10px;
    text-align: center;
  }
  .authorization-row2 {
    padding: 70px 10px 40px 10px;
    flex-direction: column-reverse;
    text-align: center;
  }
  .contain-text-second {
    margin-bottom: 15px;
    margin-top: 15px;
    padding: 0 10px;
  }
  /* .vector-img-main {
    display: flex;
    justify-content: center;
    margin: 30px 0;
  } */
  /* .vector-img1 {
    width: 30px;
    height: 30px;
    position: unset;
  }
  .vector-img2 {
    width: 30px;
    height: 30px;
    position: unset;
  } */
  .get-started-btn {
    text-align: center;
  }
  .forever-text {
    text-align: center;
    font-size: 32px;
    line-height: 36px;
    padding-left: 60px;
    padding-right: 60px;
  }
  .get-started {
    padding: 40px 0 !important;
  }
}

/* .modalHeaderRow {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
} */

.modal-name-text {
  display: flex !important;
  flex-direction: column !important;
}

.modal-heading-col {
  /* align-items: center;
  justify-content: center; */
  display: flex;
  flex-direction: column;
  align-items: inherit;
  justify-content: center;
}

.person-name {
  width: 160px;
  letter-spacing: -0.1px;
}
.person-name-modal {
  width: 100%;
  letter-spacing: -0.1px;
}

.btn-close {
  font-size: 11px;
}

.modalHeaderCol {
  max-width: 156px !important;
}

.modal-backdrop.show {
  width: 100%;
  height: 100%;
  /* max-width: 100vw; */
  /* max-width: 100vw; */
}
@media screen and (max-width: 415px) {
  .modal-heading-col {
    padding-right: 0 !important;
  }
  .modalHeaderCol {
    padding-right: 0 !important;
  }
}

@media screen and (max-width: 350px) {
  .btn-btn-get-home-bottom {
    padding: 15px !important;
  }
  .person-name {
    font-size: 21px !important;
    line-height: 29px;
  }
  .person-des {
    font-size: 19px !important;
  }
  .modal-heading-col {
    padding-left: 8px !important;
    align-items: flex-start;
  }
  .modalHeaderCol {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
@media screen and (max-width: 320px) {
  .person-name {
    font-size: 19px !important;
  }
  .person-des {
    font-size: 16px !important;
  }
  .modal-desc-text {
    font-size: 14px;
  }
  .modal-header .btn-close {
    padding: 0 !important;
  }
}
